// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  projectData: {
    host: 'https://iniciotx.app/',
    termsConditions: 'https://static.iniciotx.com/terminos-y-condiciones.pdf',
    privacyPolicy: 'https://static.iniciotx.com/aviso-de-privacidad.pdf'
  },
  api: {
    host: 'https://hvcqr3uo58.execute-api.us-east-1.amazonaws.com/', // hubo algunos cambios, así que esta url (por ahora), ya no apunta a productivo
    IEhost: 'https://hvcqr3uo58.execute-api.us-east-1.amazonaws.com/', // <- ESTO APUNTA A PROD, VE CON CUIDADO
    // host: 'https://itxbackdev.iknesoft.in',
    // host: 'https://mtwt6z469a.execute-api.us-east-1.amazonaws.com',
    // IEhost: 'https://mtwt6z469a.execute-api.us-east-1.amazonaws.com', //import, export host
    // host: 'https://iniciotxservice.azurewebsites.net/api',
    // IEhost: 'https://iniciotxservice.azurewebsites.net/api', //import, export host
    // host: 'http://localhost:8081',
    // IEhost: 'http://localhost:8081', //import, export host
    // phpHost: 'https://hvcqr3uo58.execute-api.us-east-1.amazonaws.com/',
    // phpIEhost: 'https://hvcqr3uo58.execute-api.us-east-1.amazonaws.com/', //import, export host
    // IEhost: 'https://itxbackdev.iknesoft.in', //import, export host
    postalCode: 'https://postal.iknesoft.in/postal'
  },
  userTypeObjectId: {
    laboratoryUser: '1ebbca27-c2a2-6fd8-8438-2cf05d694255'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
